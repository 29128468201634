import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useLocation } from 'react-router-dom';

import axios from 'axios';

import { Container, Grid } from '@mui/material';

import { Backup } from 'src/components/Backup';
import { Filters } from 'src/components/Filters';
import { JobsTable } from 'src/features/JobsTable';
import { ActionBar } from 'src/features/JobsTable/components/ActionBar';
import { useJobs } from 'src/features/JobsTable/utilities/hooks';
import { Tasks } from 'src/features/Tasks';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { API } from 'src/utilities/api';
import { usePreference } from 'src/utilities/hooks';

export function Dashboard() {
  const clientIdPreference = usePreference('sys.mid', '');
  const dispatch = useDispatch();
  const {
    approvalTasks,
    areActiveFiltersVisible,
    areJobsFetching,
    columns,
    dashboardUserId,
    deadlines,
    handleChangePageSearch,
    handleToggleActiveFiltersVisibility,
    isMyBackupUserActive,
    isUserIBackupActive,
    jobTasks,
    rows,
    searchTerm,
    setAreActiveFiltersVisible,
    setDashboardUserId,
    setIsMyBackupUserActive,
    setIsUserIBackupActive,
    setPageHistory,
    statusChangeTasks,
    totalRowCount,
  } = useJobs();
  const location = useLocation();
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [users, setUsers] = useState({});

  useEffect(() => {
    const source = axios.CancelToken.source();

    (async (cancelToken) => {
      await API.get('/membership/getteammembers', {
        cancelToken: cancelToken,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onError: (error) => {
          dispatch(
            openWaveSnack({
              message: error.message,
              type: 'error',
            }),
          );
          setIsLoadingUsers(false);
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onSuccess: (response) => {
          setIsLoadingUsers(false);
          setUsers(response);
        },
      });
    })(source.token);

    return () => source.cancel();
  }, [clientIdPreference.value]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (location.state?.missingRights) {
      dispatch(
        openWaveSnack({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          message: `You do not have rights to ${location.state.missingRights}.`,
          type: 'error',
        }),
      );
    }
  }, [location]);

  return (
    <>
      <Container classes={{ root: 'p-0' }} maxWidth={false}>
        <Grid className="p-10" container spacing={2}>
          <Grid item xs={9}>
            <Tasks
              approvalTasks={approvalTasks}
              isLoadingUsers={isLoadingUsers}
              jobTasks={jobTasks}
              loading={areJobsFetching}
              onSetTeammateID={setDashboardUserId}
              statusChangeTasks={statusChangeTasks}
              teammateID={dashboardUserId}
              users={users}
            />
          </Grid>

          <Grid item xs={3}>
            <Backup
              myBackupUserActive={isMyBackupUserActive}
              onSetMyBackupUserActive={setIsMyBackupUserActive}
              onSetUserIBackupActive={setIsUserIBackupActive}
              userIBackupActive={isUserIBackupActive}
              users={users}
            />
          </Grid>
        </Grid>
      </Container>

      <Container className="py-5" maxWidth={false}>
        <ActionBar
          dashboardUserId={dashboardUserId}
          onChangePageSearch={handleChangePageSearch}
          onToggleActiveFiltersVisibility={handleToggleActiveFiltersVisibility}
          searchTerm={searchTerm}
        />

        <Filters
          areActiveFiltersVisible={areActiveFiltersVisible}
          setAreActiveFiltersVisible={setAreActiveFiltersVisible}
        />
      </Container>

      <JobsTable
        areJobsFetching={areJobsFetching}
        columns={columns}
        deadlines={deadlines}
        rows={rows}
        setPageHistory={setPageHistory}
        totalRowCount={totalRowCount}
      />
    </>
  );
}
