import { Trans } from 'react-i18next';

import { Box, Button } from '@mui/material';

import { ActiveFilter } from 'src/features/ActiveFilters/components/ActiveFilter';
import { isFiltersOfJobs } from 'src/features/ActiveFilters/utilities/helperFunctions';
import { WaveIcon } from 'src/features/WaveIcon';
import { ActiveFilter as ActiveFilterType } from 'src/utilities/hooks/usePreference';

import { Filter } from 'src/features/History/History.types';

type CommonProps = { onDeleteAllActiveFilters: () => void };
export type BasicFilters = {
  activeFilters: Filter[];
  filterLabel?: string;
  onDeleteActiveFilter: (filter: Pick<BasicFilters, 'activeFilters'>['activeFilters'][0]) => void;
};
export type FiltersOfJobs = {
  activeFilters: ActiveFilterType[];
  areActiveFiltersLoading: boolean;
  onDeleteActiveFilter: (filter: Pick<FiltersOfJobs, 'activeFilters'>['activeFilters'][0]) => void;
};
export type ActiveFiltersProps = (BasicFilters | FiltersOfJobs) & CommonProps;

export function ActiveFilters({ onDeleteAllActiveFilters, ...restOfProps }: ActiveFiltersProps) {
  if (!restOfProps.activeFilters.length) return null;

  return (
    <Box alignItems="center" display="flex" gap={1} my={2}>
      {restOfProps.activeFilters.length > 1 ? (
        <Button
          disabled={isFiltersOfJobs(restOfProps) ? restOfProps.areActiveFiltersLoading : false}
          onClick={onDeleteAllActiveFilters}
          startIcon={<WaveIcon code="close" />}
        >
          <Trans i18nKey="remove_all_button">Remove all</Trans>
        </Button>
      ) : null}

      <Box display="flex" flexWrap="wrap" gap={1}>
        {isFiltersOfJobs(restOfProps)
          ? restOfProps.activeFilters.map((filter) => (
              <ActiveFilter
                areActiveFiltersLoading={restOfProps.areActiveFiltersLoading}
                filter={filter}
                key={filter.textValue}
                onDeleteActiveFilter={restOfProps.onDeleteActiveFilter}
              />
            ))
          : restOfProps.activeFilters.map((filter) => (
              <ActiveFilter
                filter={filter}
                filterLabel={restOfProps.filterLabel}
                key={filter.text}
                onDeleteActiveFilter={restOfProps.onDeleteActiveFilter}
              />
            ))}
      </Box>
    </Box>
  );
}
