import { Container } from '@mui/material';

import { Filters } from 'src/components/Filters';
import { JobsTable } from 'src/features/JobsTable';
import { ActionBar } from 'src/features/JobsTable/components/ActionBar';
import { useJobs } from 'src/features/JobsTable/utilities/hooks';

export function SubJobs() {
  const {
    areActiveFiltersVisible,
    areJobsFetching,
    columns,
    deadlines,
    handleChangePageSearch,
    handleToggleActiveFiltersVisibility,
    rows,
    searchTerm,
    setAreActiveFiltersVisible,
    setPageHistory,
    totalRowCount,
  } = useJobs();

  return (
    <>
      <Container maxWidth={false} sx={{ py: 1 }}>
        <ActionBar
          onChangePageSearch={handleChangePageSearch}
          onToggleActiveFiltersVisibility={handleToggleActiveFiltersVisibility}
          searchTerm={searchTerm}
        />

        <Filters
          areActiveFiltersVisible={areActiveFiltersVisible}
          setAreActiveFiltersVisible={setAreActiveFiltersVisible}
        />
      </Container>

      <JobsTable
        areJobsFetching={areJobsFetching}
        columns={columns}
        deadlines={deadlines}
        rows={rows}
        setPageHistory={setPageHistory}
        totalRowCount={totalRowCount}
      />
    </>
  );
}
