import { FormEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isEqual } from 'lodash';

import { PropsOfFilters } from 'src/components/Filters/Filters';
import {
  computeChangedFormFilters,
  computeDefaultValues,
  extractFieldDataByAlias,
  filterFormFiltersByAlias,
  filterValueFromCommaSeparatedString,
  haveFiltersChanged as computeHaveFiltersChanged,
} from 'src/components/Filters/utilities/helperFunctions';
import { useFieldSelectorFields } from 'src/features/JobsTable/utilities/hooks';
import { FieldTransformed, FieldValue, FieldValues } from 'src/pages/Job/Job.service';
import { isString } from 'src/utilities/helperFunctions2';
import { usePreference, usePreferencePrefix } from 'src/utilities/hooks';
import { ActiveFilter } from 'src/utilities/hooks/usePreference';

type PropsOfUseFilters = PropsOfFilters;

export function useFilters({
  areActiveFiltersVisible,
  setAreActiveFiltersVisible,
}: PropsOfUseFilters) {
  const { t: translate } = useTranslation();
  const useFormReturn = useForm();
  const { getValues, reset, setValue, watch } = useFormReturn;

  const [isFieldSelectorOpen, setIsFieldSelectorOpen] = useState(false);

  const { jobType, preferencePrefix } = usePreferencePrefix();

  const pagePreference = usePreference(`${preferencePrefix}.listPage`, '0');

  const searchFiltersPreference = usePreference(`${preferencePrefix}.filterFields`, '');
  const { savedAvailableFields, savedSelectedFields } = useFieldSelectorFields({
    fieldPreference: searchFiltersPreference,
    isFieldSelectorOpen: !areActiveFiltersVisible,
    type: 'search',
  });

  const activeFiltersPreference = usePreference(`${preferencePrefix}.search`, []);
  const areActiveFiltersLoading = activeFiltersPreference.isLoading;
  const activeFilters = activeFiltersPreference.value as ActiveFilter[];
  const [formFilters, setFormFilters] = useState<ActiveFilter[]>([]);
  const haveFiltersChanged = computeHaveFiltersChanged(activeFilters, formFilters);

  function handleFieldChange(
    formData: FieldValues,
    savedSelectedFields: FieldTransformed[],
    changedFieldAlias?: string,
  ) {
    if (!changedFieldAlias) return;

    const {
      fieldData: changedFieldOptions,
      name: changedFieldLabel,
      type: changedFieldType,
    } = extractFieldDataByAlias(changedFieldAlias, savedSelectedFields);
    const changedFieldValue = changedFieldAlias ? formData[changedFieldAlias] : undefined;

    if (
      changedFieldLabel &&
      changedFieldOptions &&
      Array.isArray(changedFieldOptions) &&
      changedFieldType &&
      changedFieldValue !== undefined
    ) {
      const changedFormFilters = computeChangedFormFilters(
        changedFieldAlias,
        changedFieldLabel,
        changedFieldOptions,
        changedFieldType,
        changedFieldValue,
      );

      setFormFilters((previousFormFilters) => {
        // Filter out all formFilters for this field to start fresh
        const previousFormFiltersFiltered = filterFormFiltersByAlias(
          previousFormFilters,
          changedFieldAlias,
        );

        return [...previousFormFiltersFiltered, ...changedFormFilters];
      });
    }
  }

  function handleCloseFieldSelector() {
    setIsFieldSelectorOpen(false);
  }

  function handleOpenFieldSelector() {
    setIsFieldSelectorOpen(true);
  }

  function handleApplyFieldSelector(selectedFields?: FieldTransformed[]) {
    searchFiltersPreference.set(selectedFields?.map(({ id }) => id).join() || '');
  }

  function handleDeleteFormFilter(deleteFilter: ActiveFilter) {
    const deleteFilterKey = Object.keys(deleteFilter)[0];
    const deleteFilterValue = deleteFilter[deleteFilterKey];
    const fieldValue: FieldValue = getValues(deleteFilterKey);

    if (fieldValue === true) setValue(deleteFilterKey, !fieldValue);

    if (isString(fieldValue)) {
      const filteredValue = filterValueFromCommaSeparatedString(deleteFilterValue, fieldValue);

      setValue(deleteFilterKey, filteredValue);
    }
  }

  function handleDeleteAllFormFilters() {
    if (savedSelectedFields) resetForm(savedSelectedFields);
  }

  function resetForm(savedSelectedFields: FieldTransformed[], activeFilters?: ActiveFilter[]) {
    const defaultValues = computeDefaultValues(savedSelectedFields, activeFilters);

    reset(defaultValues);
    setFormFilters(activeFilters ?? []);
  }

  function handleDeleteActiveFilter(deleteFilter: ActiveFilter) {
    const filteredActiveFilters = activeFilters.filter(
      (activeFilter) => !isEqual(activeFilter, deleteFilter),
    );

    activeFiltersPreference.set(filteredActiveFilters);
  }

  function handleDeleteAllActiveFilters() {
    activeFiltersPreference.set([]);
  }

  function handleSubmitForm(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    activeFiltersPreference.set(formFilters);
    pagePreference.set('0');
  }

  useEffect(() => {
    setAreActiveFiltersVisible(true);
  }, [activeFilters, jobType]);

  useEffect(() => {
    if (savedSelectedFields) resetForm(savedSelectedFields, activeFilters);
  }, [activeFilters, savedSelectedFields]);

  useEffect(() => {
    if (!savedSelectedFields) return;

    const subscription = watch((formData, { name: changedFieldAlias }) =>
      handleFieldChange(formData, savedSelectedFields, changedFieldAlias),
    );

    return () => subscription.unsubscribe();
  }, [savedSelectedFields, watch]);

  return {
    activeFilters,
    areActiveFiltersLoading,
    formFilters,
    handleApplyFieldSelector,
    handleCloseFieldSelector,
    handleDeleteActiveFilter,
    handleDeleteAllActiveFilters,
    handleDeleteAllFormFilters,
    handleDeleteFormFilter,
    handleOpenFieldSelector,
    handleSubmitForm,
    haveFiltersChanged,
    isFieldSelectorOpen,
    savedAvailableFields,
    savedSelectedFields,
    searchFiltersPreference,
    translate,
    useFormReturn,
  };
}
