import { useTranslation } from 'react-i18next';

import { useNavigate, useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Container, Divider } from '@mui/material';

import { BreadCrumbs } from 'src/components/BreadCrumbs';
import { Filters } from 'src/components/Filters';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { jobFormApi } from 'src/features/JobForm/JobForm.service';
import { JobsTable } from 'src/features/JobsTable';
import { ActionBar } from 'src/features/JobsTable/components/ActionBar';
import { useJobs } from 'src/features/JobsTable/utilities/hooks';
import { useUpdateAssignToProjectMutation } from 'src/pages/AssignToProject/AssignToProject.service';
import { jobApi } from 'src/pages/Job/Job.service';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch, useRouteParams } from 'src/utilities/hooks';

export function AssignToProject() {
  const dispatch = useAppDispatch();
  const {
    areActiveFiltersVisible,
    areJobsFetching,
    columns,
    deadlines,
    handleChangePageSearch,
    handleClickRowRadio,
    handleToggleActiveFiltersVisibility,
    rows,
    searchTerm,
    selectedRows,
    setAreActiveFiltersVisible,
    setPageHistory,
    setSelectedRows,
    totalRowCount,
  } = useJobs();

  const navigate = useNavigate();
  const { jobId } = useRouteParams();
  const { jobType: unAlteredJobType } = useParams();

  const [updateAssignToProject, { isLoading: isUpdateAssignToProjectLoading }] =
    useUpdateAssignToProjectMutation();
  const { t: translate } = useTranslation();

  function handleCancel() {
    navigate(`/jobs-job-${unAlteredJobType}/${jobId}/job`);
  }

  async function handleConfirm() {
    updateAssignToProject({
      assignToJobId: selectedRows[0],
      assignToJobType: 'pro',
      jobId,
      jobType: unAlteredJobType,
    })
      .unwrap()
      .then((message) => {
        dispatch(
          openWaveSnack({
            message,
            type: 'success',
          }),
        );
        dispatch(jobFormApi.util.invalidateTags(['Actions', 'RelatedJobs']));
        dispatch(jobApi.util.invalidateTags(['Job']));
        navigate(`/jobs-job-${unAlteredJobType}/${jobId}/job`);
      })
      .catch(() => {
        setSelectedRows([]);
      });
  }

  return (
    <>
      <BreadCrumbs />

      <Box sx={{ alignItems: 'center', display: 'flex', gap: 1, justifyContent: 'flex-end', m: 1 }}>
        <Button color="warning" onClick={handleCancel}>
          {translate('lib.cancel')}
        </Button>

        <WaveTooltip
          body={selectedRows.length === 0 ? 'Please select a project.' : ''}
          component={
            <LoadingButton
              disabled={!selectedRows.length}
              loading={isUpdateAssignToProjectLoading}
              onClick={handleConfirm}
              variant="contained"
            >
              {translate('lib.confirm')}
            </LoadingButton>
          }
          placement="top"
          type="simple"
        />
      </Box>

      <Divider />

      <Container maxWidth={false} sx={{ py: 0.5 }}>
        <ActionBar
          onChangePageSearch={handleChangePageSearch}
          onToggleActiveFiltersVisibility={handleToggleActiveFiltersVisibility}
          searchTerm={searchTerm}
          selectedRows={selectedRows}
        />

        <Filters
          areActiveFiltersVisible={areActiveFiltersVisible}
          setAreActiveFiltersVisible={setAreActiveFiltersVisible}
        />
      </Container>

      <JobsTable
        areJobsFetching={areJobsFetching}
        columns={columns}
        deadlines={deadlines}
        onClickRowRadio={handleClickRowRadio}
        rows={rows}
        selectedRows={selectedRows}
        setPageHistory={setPageHistory}
        setSelectedRows={setSelectedRows}
        totalRowCount={totalRowCount}
      />
    </>
  );
}
