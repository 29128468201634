import { AutocompleteFreeSoloValueMapping } from '@mui/material';

import { Option } from 'src/pages/Job/Job.service';
import { isNumber, isString, naturalSort } from 'src/utilities/helperFunctions2';

export function getOptionLabel<T, FreeSolo>(
  option: T | AutocompleteFreeSoloValueMapping<FreeSolo>,
): string {
  if (getIsOption(option)) return option.label;

  if (isString(option)) return option;

  if (isNumber(option)) return option.toString();

  if (option && typeof option === 'object' && 'label' in option) return (option as any).label;

  return 'Unknown';
}

export function getOptionValue<T, FreeSolo>(
  option: T | AutocompleteFreeSoloValueMapping<FreeSolo>,
): string {
  if (getIsOption(option)) return option.value.toString();

  if (isString(option)) return option;

  if (isNumber(option)) return option.toString();

  if (option && typeof option === 'object' && 'value' in option) {
    return (option as any).value.toString();
  }

  return 'Unknown';
}

export function getValueLabel<T>(value: string, options: ReadonlyArray<T>): Option | undefined {
  return options.find((option) => value.toString() === (option as Option).value.toString()) as
    | Option
    | undefined;
}

function getStringOrNumber<T>(value: string | number, options: ReadonlyArray<T>): Array<Option> {
  return value
    .toString()
    .split(',')
    .map((mappedValue) => {
      const option = getValueLabel(mappedValue, options);
      const labelValue = option ? option.label : mappedValue.toString();

      return { label: labelValue, value: mappedValue.toString() };
    });
}

function getArray<T>(
  value: Array<string | number | Option> | Array<Array<string | number | Option>>,
  options: ReadonlyArray<T>,
  fieldValueIndex?: number,
): Array<Option> {
  if (fieldValueIndex !== undefined) {
    if (Array.isArray(value[fieldValueIndex])) {
      return (value[fieldValueIndex] as Array<string | number | Option>).map((val) =>
        getIsOption(val) ? val : { label: val.toString(), value: val.toString() },
      );
    } else if (isString(value[fieldValueIndex]) || isNumber(value[fieldValueIndex])) {
      return getStringOrNumber(value[fieldValueIndex] as string | number, options);
    }
  } else {
    return value.map((val) =>
      getIsOption(val) ? val : { label: val.toString(), value: val.toString() },
    );
  }

  return [];
}

export function getIsOption(param: unknown): param is Option {
  return Boolean((param as Option).value !== undefined && (param as Option).label !== undefined);
}

function getSingleValue(value: string | number | Option): Array<Option> {
  return getIsOption(value) ? [value] : [{ label: value.toString(), value: value.toString() }];
}

export function getFieldValue<T>(
  value: Array<Array<string>> | Array<string> | Option | string | number | null,
  options: ReadonlyArray<T>,
  fieldValueIndex?: number,
): Array<Option> {
  if (!value || (Array.isArray(value) && !value.length)) return [];

  if (isString(value) || isNumber(value)) return getStringOrNumber<T>(value, options);

  if (Array.isArray(value)) return getArray<T>(value, options, fieldValueIndex);

  return getSingleValue(value);
}

export function getFormattedAutocompleteResults<T>(
  result: T[],
  freeSolo: boolean | undefined,
): string | Array<T> | null {
  if (!result.length) return null;

  return freeSolo
    ? result
    : result
        .slice()
        .sort((firstValue, secondValue) => naturalSort(firstValue as string, secondValue as string))
        .join(',');
}

export function getAutoCompleteValuesResults<T>(values: T[]): Array<string | T> {
  const result: (string | T)[] = [];

  if (Array.isArray(values) && values.length > 0) {
    values.forEach((item) => {
      result.push(getIsOption(item) ? item.value.toString() : item);
    });
  }

  return result;
}
