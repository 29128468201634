import { Dispatch, SetStateAction } from 'react';
import { FormProvider } from 'react-hook-form';
import { Trans } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';

import { Button } from 'src/components/Button';
import { useFilters } from 'src/components/Filters/useFilters';
import { ActiveFilters } from 'src/features/ActiveFilters';
import { FieldMapper2 } from 'src/features/JobForm/components/FieldMapper2';
import { FieldSelector } from 'src/features/JobsTable/components/FieldSelector';
import { WaveIcon } from 'src/features/WaveIcon';

export type PropsOfFilters = {
  areActiveFiltersVisible: boolean;
  setAreActiveFiltersVisible: Dispatch<SetStateAction<boolean>>;
};

export function Filters({ areActiveFiltersVisible, setAreActiveFiltersVisible }: PropsOfFilters) {
  const {
    activeFilters,
    areActiveFiltersLoading,
    formFilters,
    handleApplyFieldSelector,
    handleCloseFieldSelector,
    handleDeleteActiveFilter,
    handleDeleteAllActiveFilters,
    handleDeleteAllFormFilters,
    handleDeleteFormFilter,
    handleOpenFieldSelector,
    handleSubmitForm,
    haveFiltersChanged,
    isFieldSelectorOpen,
    savedAvailableFields,
    savedSelectedFields,
    searchFiltersPreference,
    translate,
    useFormReturn,
  } = useFilters({
    areActiveFiltersVisible,
    setAreActiveFiltersVisible,
  });

  if (areActiveFiltersVisible && !activeFilters.length) return null;

  if (areActiveFiltersVisible && activeFilters.length) {
    return (
      <ActiveFilters
        activeFilters={activeFilters}
        areActiveFiltersLoading={areActiveFiltersLoading}
        onDeleteActiveFilter={handleDeleteActiveFilter}
        onDeleteAllActiveFilters={handleDeleteAllActiveFilters}
      />
    );
  }

  return (
    <FormProvider {...useFormReturn}>
      <form onSubmit={handleSubmitForm}>
        <Box display="flex" justifyContent="flex-end" my={1}>
          <Button
            disabled={!savedAvailableFields?.length}
            onClick={handleOpenFieldSelector}
            // TODO: Update WaveIcon code to include parent location
            startIcon={<WaveIcon code="visibility" />}
            variant="text"
          >
            <Trans i18nKey="select_filter_fields_button">Select Filter Fields</Trans>
          </Button>

          <LoadingButton
            disabled={!haveFiltersChanged}
            loading={areActiveFiltersLoading}
            // TODO: Update WaveIcon code to include parent location
            startIcon={<WaveIcon code="done" />}
            type="submit"
            variant="text"
          >
            <Trans i18nKey="apply_filters_button">Apply Filters</Trans>
          </LoadingButton>
        </Box>

        {formFilters.length ? (
          <ActiveFilters
            activeFilters={formFilters}
            areActiveFiltersLoading={areActiveFiltersLoading}
            onDeleteActiveFilter={handleDeleteFormFilter}
            onDeleteAllActiveFilters={handleDeleteAllFormFilters}
          />
        ) : null}

        <Box display="flex" flexWrap="wrap" gap={2}>
          {savedSelectedFields?.map(
            ({ alias, description, fieldData, id, isDisabled, name, type }) => (
              <FieldMapper2
                alias={alias}
                description={description}
                fieldData={fieldData}
                isDisabled={isDisabled}
                isRange
                key={id}
                name={name}
                shouldUseIntersectionObserver={false}
                type={type}
              />
            ),
          )}
        </Box>
      </form>

      {isFieldSelectorOpen ? (
        <FieldSelector
          fieldPreference={searchFiltersPreference}
          isOpen={isFieldSelectorOpen}
          onApplyFieldSelector={handleApplyFieldSelector}
          onCloseFieldSelector={handleCloseFieldSelector}
          savedAvailableFields={savedAvailableFields}
          savedSelectedFields={savedSelectedFields}
          type={translate('filter_button', 'Filter')}
        />
      ) : null}
    </FormProvider>
  );
}
