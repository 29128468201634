import { DATE_SEPARATOR } from 'src/components/RHF/DateField/utilities/constants';
import { BOOLEAN_DISPLAY_VALUE } from 'src/features/ActiveFilters/components/ActiveFilter/utilities/constants';
import { isSelectField } from 'src/features/JobForm/components/FieldMapper2/utilities/helperFunctions';
import {
  FieldTransformed,
  FieldType,
  FieldValue,
  FieldValues,
  Options,
} from 'src/pages/Job/Job.service';
import { computeDefaultFieldValueByType } from 'src/pages/Job/utilities/helperFunctions';
import { isString, sortArrayOfObjectsByKey } from 'src/utilities/helperFunctions2';
import { ActiveFilter } from 'src/utilities/hooks/usePreference';

export function haveFiltersChanged(
  activeFilters: ActiveFilter[],
  formFilters: ActiveFilter[],
): boolean {
  if (activeFilters.length !== formFilters.length) return true;

  const areActiveFiltersUnchanged = activeFilters.every((activeFilter) =>
    formFilters.some((formFilter) => formFilter.textValue === activeFilter.textValue),
  );

  const areFormFiltersUnchanged = formFilters.every((formFilter) =>
    activeFilters.some((activeFilter) => activeFilter.textValue === formFilter.textValue),
  );

  return !(areActiveFiltersUnchanged && areFormFiltersUnchanged);
}

export function computeDefaultValues(
  savedSelectedFields: FieldTransformed[],
  activeFilters?: ActiveFilter[],
) {
  return savedSelectedFields.reduce((defaultValues, { alias, type }) => {
    const savedValue = extractValueFromActiveFilterByAlias(alias, activeFilters);

    defaultValues[alias] = savedValue ?? computeDefaultFieldValueByType(type);

    return defaultValues;
  }, {} as FieldValues);
}

export function extractValueFromActiveFilterByAlias(alias: string, activeFilters?: ActiveFilter[]) {
  return activeFilters
    ? activeFilters.reduce<string | undefined>((value, activeFilter) => {
        const doesActiveFilterMatchAlias = Object.keys(activeFilter).includes(alias);

        if (doesActiveFilterMatchAlias) {
          const activeFilterValue = activeFilter[alias];

          value = !value ? activeFilterValue : `${value}, ${activeFilterValue}`;
        }

        return value;
      }, undefined)
    : undefined;
}

export function extractFieldDataByAlias(alias: string, fields: FieldTransformed[]) {
  const field = fields.find((field) => field.alias === alias);
  const fieldData = field?.fieldData;
  const name = field?.name;
  const type = field?.type;

  return { fieldData, name, type };
}

export function computeChangedFormFilters(
  alias: string,
  label: string,
  options: Options,
  type: FieldType,
  value: FieldValue,
): ActiveFilter[] {
  if (!value) return [];

  if (isString(value)) {
    const commaSeparatedValues = transformToCommaSeparatedValues(value);

    return sortArrayOfObjectsByKey(
      commaSeparatedValues.map((value) => buildFormFilter(alias, label, options, type, value)),
      'textValue',
    );
  }

  if (value === true) {
    return [buildFormFilter(alias, label, options, type, BOOLEAN_DISPLAY_VALUE)];
  }

  const isDateRangeFilter = Array.isArray(value);

  if (isDateRangeFilter) {
    return [buildFormFilter(alias, label, options, type, value.join(DATE_SEPARATOR))];
  }

  return [];
}

function buildFormFilter(
  alias: string,
  label: string,
  options: Options,
  type: FieldType,
  value: string,
) {
  return {
    [alias]: value,
    label,
    textValue: computeTextValue(label, options, type, value),
  };
}

function computeTextValue(label: string, options: Options, type: FieldType, value: string) {
  return isSelectField(type) ? extractOptionLabel(label, options, value) : value;
}

function extractOptionLabel(label: string, options: Options, value: string) {
  return options.reduce(
    (textValue, option) => (option.value.toString() === value ? option.label : textValue),
    '',
  );
}

export function filterFormFiltersByAlias(formFilters: ActiveFilter[], alias: string) {
  return formFilters.filter((formFilter) => {
    const formFiltersAlias = Object.keys(formFilter)[0];

    return formFiltersAlias !== alias;
  });
}

export function filterValueFromCommaSeparatedString(
  deleteValue: string,
  commaSeparatedString: string,
) {
  const commaSeparatedValues = transformToCommaSeparatedValues(commaSeparatedString);

  return commaSeparatedValues.filter((value) => value !== deleteValue).join();
}

function transformToCommaSeparatedValues(commaSeparatedString: string) {
  const commaSeparatedValues = commaSeparatedString
    .split(',')
    .reduce<string[]>((commaSeparatedValues, value) => {
      const trimmedValue = value.trim();

      if (trimmedValue) commaSeparatedValues.push(trimmedValue);

      return commaSeparatedValues;
    }, []);

  return commaSeparatedValues;
}
