import dayjs from 'dayjs';

import { DATE_SEPARATOR, LONG_DATE_FORMAT } from 'src/components/RHF/DateField/utilities/constants';
import {
  ActiveFilterProps,
  BasicFilter,
  JobsFilter,
} from 'src/features/ActiveFilters/components/ActiveFilter/ActiveFilter';
import { isNumber } from 'src/utilities/helperFunctions2';
import { useDateFormat } from 'src/utilities/hooks';

export function buildLabel(props: ActiveFilterProps) {
  if (isJobsFilter(props)) {
    const filterKey = Object.keys(props.filter)[0];
    const value = formatValue(props.filter.textValue) || formatValue(props.filter[filterKey]);

    return `${props.filter.label}: ${value}`;
  }

  const filterLabel = props.filterLabel;

  return `${filterLabel ? `${filterLabel}: ` : ''}${props.filter.text}`;
}

export function isJobsFilter(props: BasicFilter | JobsFilter): props is JobsFilter {
  return 'areActiveFiltersLoading' in props;
}

function formatValue(value: string): string {
  const hasAlphabeticalCharacters = /[a-zA-Z]/g.test(value);
  const date = dayjs(value);
  const isValidDate = date.isValid();
  const hasDashes = value.includes('-');
  const hasTwoDates = value.includes(DATE_SEPARATOR);
  const isNotADate =
    hasAlphabeticalCharacters ||
    (isNumber(parseInt(value)) && !hasDashes) ||
    (!isValidDate && !hasTwoDates);

  if (isNotADate) return value;

  const { formatDate } = useDateFormat();

  if (hasTwoDates) {
    const splitDates = value
      .split(DATE_SEPARATOR)
      .map((value) => formatDate(LONG_DATE_FORMAT, value) as string);

    return splitDates.join(DATE_SEPARATOR);
  }

  return formatDate(LONG_DATE_FORMAT, value) as string;
}
