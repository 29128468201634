import { UseControllerProps } from 'react-hook-form';

import { Dayjs } from 'dayjs';

import { Box, BoxProps, TextField, Typography } from '@mui/material';
import { DatePicker, DatePickerProps, DatePickerSlots, PickersDay } from '@mui/x-date-pickers';

import { useDateField } from 'src/components/RHF/DateField/useDateField';
import { isDaySelected } from 'src/components/RHF/DateField/utilities/helperFunctions';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { WaveIcon } from 'src/features/WaveIcon';

export type DateFieldProps = {
  description?: string;
  isRange?: boolean;
  isRawText?: boolean;
  isReadOnly?: boolean;
  onChangeLogicBuilderField?: (changedFieldAlias: string) => void;
  muiBoxDatePickerWrapperProps?: Omit<BoxProps, 'ref'>;
  muiDatePickerProps: ModifiedMuiDatePickerProps;
  shouldShowHelperText?: boolean;
  useControllerProps: UseControllerProps;
};
export type ModifiedMuiDatePickerProps = Omit<
  DatePickerProps<Dayjs>,
  'onChange' | 'onClose' | 'open' | 'name' | 'slots'
> &
  ModifiedSlots;
type ModifiedSlots = { slots?: Omit<DatePickerSlots<Dayjs>, 'day' | 'textField'> };

export function DateField({
  description,
  isRange = false,
  isRawText = false,
  isReadOnly = false,
  muiBoxDatePickerWrapperProps,
  muiDatePickerProps,
  onChangeLogicBuilderField,
  shouldShowHelperText = true,
  useControllerProps,
}: DateFieldProps) {
  const {
    anchorReference,
    handleChange,
    handleClose,
    handleOpen,
    isOpen,
    label,
    name,
    restOfDatePickerProps,
    shouldShowTooltip,
    slots,
    stringifiedValue,
    value,
  } = useDateField({
    description,
    isRange,
    muiDatePickerProps,
    onChangeLogicBuilderField,
    shouldShowHelperText,
    useControllerProps,
  });

  if (isRawText) {
    return <Typography>{value}</Typography>;
  }

  if (isReadOnly) {
    return (
      <Box>
        <Typography color="text.secondary" variant="caption">
          {label}
        </Typography>

        <Typography sx={{ minHeight: 24 }}>{value}</Typography>
      </Box>
    );
  }

  return (
    <Box ref={anchorReference} width={isRange ? 288 : '100%'} {...muiBoxDatePickerWrapperProps}>
      <DatePicker
        label={label}
        name={name}
        onChange={handleChange}
        onClose={handleClose}
        open={isOpen}
        slots={{
          ...slots,
          day: ({ day, onDaySelect, ...restOfDayProps }) => (
            <PickersDay
              {...restOfDayProps}
              day={day}
              onDaySelect={(day) => {
                handleChange(day);
                onDaySelect(day);
              }}
              selected={isDaySelected(day, value)}
            />
          ),
          textField: (props) => (
            <TextField {...props} fullWidth onClick={handleOpen} value={stringifiedValue} />
          ),
        }}
        {...restOfDatePickerProps}
      />

      {shouldShowTooltip ? (
        <WaveTooltip
          body={description}
          component={<WaveIcon code="input-field-information" />}
          placement="top"
          type="simple"
        />
      ) : null}
    </Box>
  );
}
